<template>
  <b-card
    title="Returns"
    id="Returns"
    v-if="this.joke"
    :class="borderVariant"
  >
    <b-card-text>
      Graphic below shows Returns of the specific Company. Within the Progressbar stands the underlying return rate, color and progress are indicators to visualize the value of the specific rates.
    </b-card-text>

    <div class="demo-vertical-spacing">
      <div v-for="(dropdown, index) in dropdowns" :key="index">
        <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :text="selectedOptions[index] ? selectedOptions[index] : dropdown.defaultText"
          variant="flat-secondary"
          v-model="selectedOptions[index]"
        >
          <b-dropdown-item v-for="option in dropdown.options" :key="option" @click="updateValue(index, option)">
            {{ option }}
          </b-dropdown-item>
        </b-dropdown>
        <b-progress
          show-value
          :value="getValue(selectedOptions[index])"
          max="25"
          striped
          :variant="variant(getValue(selectedOptions[index]))"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import { BProgress, BCardText, BCard, BDropdown, BDropdownItem } from 'bootstrap-vue';

export default {
  components: {
    BProgress,
    BCardText,
    BCard,
    BDropdown,
    BDropdownItem,
  },
  computed: {
    joke() {
      return this.$store.getters.getCurrentCompdata;
    },
    borderVariant() {
      const avgValue = this.averageValue;
      if (avgValue < 5) {
        return 'border-danger';
      }
      if (avgValue >= 5 && avgValue < 10) {
        return 'border-warning';
      }
      if (avgValue >= 10) {
        return 'border-success';
      }
      return '';
    },
    averageValue() {
      const values = this.selectedOptions.map(option => this.joke ? this.joke[this.mapping[option]] : 0);
      const sum = values.reduce((acc, value) => acc + value, 0);
      return values.length > 0 ? sum / values.length : 0;
    }
  },
  data() {
    return {
      selectedOptions: ['Return on Assets', 'Return on Employed Capital', 'Return on Equity'],
      dropdowns: [
        { defaultText: 'Return on Assets', options: ['Return on Assets', 'Return on Employed Capital', 'Return on Equity'] },
        { defaultText: 'Return on Employed Capital', options: ['Return on Assets', 'Return on Employed Capital', 'Return on Equity'] },
        { defaultText: 'Return on Equity', options: ['Return on Assets', 'Return on Employed Capital', 'Return on Equity'] },
      ],
      mapping: {
        'Return on Assets': 'ROA',
        'Return on Employed Capital': 'ROIC',
        'Return on Equity': 'ROE',
      },
    };
  },
  methods: {
    updateValue(index, selectedOption) {
      this.$set(this.selectedOptions, index, selectedOption);
    },
    getValue(selectedOption) {
      const abbreviation = this.mapping[selectedOption];
      if (this.joke === undefined) {
        return 0;
      }
      return this.joke[abbreviation];
    },
    variant(value) {
      if (value < 5) {
        return 'danger';
      }
      if (value >= 5 && value < 10) {
        return 'warning';
      }
      if (value >= 10) {
        return 'success';
      }
    },
  },
};
</script>
