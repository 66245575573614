<template>
  <b-card v-if="joke">
    <b-card-header>
      <b-card-title class="mb-sm-0 mb-1">
        Margin-Statistics {{  ((this.OperatingIncome / this.TotalRevenue) * 100).toFixed(2)}}
      </b-card-title>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        id="RadialChart"
        type="radialBar"
        :options="apexChatData.radialBarChart.chartOptions"
        :series="series"
      />
      <div class="center-container">
        <div v-for="(dropdown, index) in dropdowns" :key="index">
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :text="selectedOptions[index] ? selectedOptions[index] : dropdown.defaultText"
            :variant="dropdown.variant"
            v-model="selectedOptions[index]"
          >
            <b-dropdown-item v-for="option in dropdown.options" :key="option" @click="updateSeries(index, option)">
              {{ option }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BCardBody, BCardHeader, BDropdown, BDropdownItem } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import apexChatData from './apexChartData';

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      apexChatData,
      selectedOptions: ['Revenue', 'Profit-Margin', 'FreeCashflow-Margin'],
      dropdowns: [
      { text: 'Revenue', variant: 'flat-primary', options: ['Revenue', 'Profit-Margin', 'FreeCashflow-Margin', 'Gross-Margin', 'Operating-Margin'], defaultText: 'Revenue' },
      { text: 'Profit-Margin', variant: 'flat-info', options: ['Revenue', 'Profit-Margin', 'FreeCashflow-Margin', 'Gross-Margin', 'Operating-Margin'], defaultText: 'Profit-Margin' },
      { text: 'FreeCashflow-Margin', variant: 'flat-success', options: ['Revenue', 'Profit-Margin', 'FreeCashflow-Margin', 'Gross-Margin', 'Operating-Margin'], defaultText: 'FreeCashflow-Margin' },
  ],
    };
  },
  computed: {
    joke() {
      return this.$store.getters.getCurrentCompdata;
    },
    TotalRevenue() {
      const array = Object.fromEntries(Object.entries(this.$store.getters.getCurrentCompdata.IncomeStatement.totalRevenue).sort())
      const lastValue = Object.values(array)[Object.values(array).length - 1];
      return lastValue
    },
    OperatingIncome() {
      const array = Object.fromEntries(Object.entries(this.$store.getters.getCurrentCompdata.IncomeStatement.operatingIncome).sort())
      const lastValue = Object.values(array)[Object.values(array).length - 1];
      return lastValue
    },
    grossProfit() {
      const array = Object.fromEntries(Object.entries(this.$store.getters.getCurrentCompdata.IncomeStatement.grossProfit).sort())
      const lastValue = Object.values(array)[Object.values(array).length - 1];
      return lastValue
    },


    series() {
    
    return this.selectedOptions.map(selectedOption => {
      switch(selectedOption) {
        case 'Revenue':
          return 100;
        case 'Profit-Margin':
        case 'FreeCashflow-Margin':
          return this.joke && this.joke[selectedOption] !== undefined ? this.joke[selectedOption] : 0;
        case 'Gross-Margin':
          // Calculate Gross Margin based on the last values of Total Revenue and Gross Profit
          return this.OperatingIncome && this.TotalRevenue !== undefined? ((this.OperatingIncome / this.TotalRevenue) * 100).toFixed(2): 0;
        case 'Operating-Margin':
          // Calculate Operating Margin based on the last values of Total Revenue and Operating Profit
          return this.grossProfit && this.TotalRevenue !== undefined? ((this.grossProfit / this.TotalRevenue) * 100).toFixed(2): 0;
        default:
          return 0;
      }
    });
  },
  },
  watch: {
    selectedOptions: {
      handler(newValues) {
        if (newValues[2] === 'FreeCashflow-Margin') {
          // Trigger any additional logic or API calls here to load the graph
        }
      },
      deep: true,
      immediate: true, // Add immediate option to trigger on component mount
    },
  },
  methods: {
    updateSeries(index, selectedOption) {
      this.$set(this.selectedOptions, index, selectedOption);
    },
  },
};
</script>

<style>
.center-container {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>