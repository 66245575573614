<template>
  <div>
    <app-tour :steps="steps"/>
    <div class="match-height">
        <b-row class="match-height">
            <b-col  lg="7">
                <apex-radial-bar-chart />
            </b-col>
            <b-col lg="5">
                <card-text-alignment />
                <progress-stripped-vue />
                <progress-stripped-return />
            </b-col>
        </b-row>
        
    </div>

  </div>
</template>

<script>

import { BRow, BCol, BLink } from 'bootstrap-vue'
import ApexRadialBarChart from './ApexRadialBarChart.vue'
import CardTextAlignment from './CardTextAlignment.vue'
import ProgressStrippedVue from './ProgressStripped.vue'
import ProgressStrippedReturn from './ProgressStrippedReturns.vue'
import AppTour from '@core/components/app-tour/AppTour.vue'
import Ripple from 'vue-ripple-directive'



export default {
  components: {
      BRow,
      BCol,
      BLink,

      ApexRadialBarChart,
      CardTextAlignment,
      ProgressStrippedVue,
      ProgressStrippedReturn,
      AppTour
  },
  directives: {
    Ripple,
  },
  data () {
        return {
          steps: [
            {
              target: '#RadialChart',
              header: {
                title: 'Whats left of the Revenue',
              },
              content: 'Here you can see the Margins of Earnings and Free Cashflow of the previous years Revenue',
            },
            {
              target: '#Margins',
              header: {
                title: 'Margins ?',
              },
              content: 'Here you can see the the different Margins. Color and length indicate how good certain values are.',
            },
            {
              target: '#Returns',
              header: {
                title: 'Returns ?',
              },
              content: 'Here you can see the the different Returns. Color and length indicate how good certain values are.',
            },
            {
              target: '#btn btn-outline-primary btn-block',
              header: {
                title: 'Questions ?',
              },
              content: 'If you have questions about certain terms or want an explaination why we only refer to certain values you can read an article about any time, by clicking this button',
            },
            {
              target: '#__BVID__1119',
              header: {
                title: 'How much is the company worth ?',
              },
              content: 'to see what the company is worth. Click the Valuation tab in the sidebar',
            }
          ],
        }
    },   
  mounted() {
    this.checkTutorial()
  },
  methods: {
    checkTutorial() {
      if(this.$store.getters.getUser.tutorial === true) {
        this.$tours.vuexyTour.start()
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/tour.scss';
</style>
