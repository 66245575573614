<template>
  <section id="card-text-alignment">
    <b-row>
      <!-- left align -->
      <b-col
        md="6"
        lg="12"
      >
        <b-card
          title="Which Metric are relevant to measure the Profitability"
          class="mb-1"
        >
          <b-card-text>
            You might be familiar with the Terms: Profit-Margin, Free-Chashflow-Margin, Return on net-tangible Assets . If you do not know them yet, you can click below to learn what these terms mean and why the are the most relevant to measure profitability of a specific Company.
          </b-card-text>
          <b-button
            block
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            v-b-modal.modal-center
          >
            Explain it to me
          </b-button>
          <b-modal
      id="modal-center"
      centered
      title="Financial Ratios Analysis"
      ok-only
      ok-title="Accept"
    >
      <b-card-text>
        <h2>Profit Margin:</h2>
    <p>
        <strong>Definition:</strong> Profit Margin is a percentage that represents the profitability of a company by measuring the proportion of profit to revenue.
    </p>
    <p>
        <strong>Formula:</strong> Profit Margin = (Net Profit / Revenue) * 100
    </p>

    <h2>Free Cash Flow Margin:</h2>
    <p>
        <strong>Definition:</strong> Free Cash Flow Margin is a percentage that shows the proportion of free cash flow to total revenue, indicating the efficiency of cash generation.
    </p>
    <p>
        <strong>Formula:</strong> Free Cash Flow Margin = (Free Cash Flow / Revenue) * 100
    </p>

    <h2>Return on Assets (ROA):</h2>
    <p>
        <strong>Definition:</strong> Return on Assets measures how efficiently a company utilizes its assets to generate earnings.
    </p>
    <p>
        <strong>Formula:</strong> Return on Assets (ROA) = (Net Income / Average Total Assets) * 100
    </p>

    <h2>Return on Equity (ROE):</h2>
    <p>
        <strong>Definition:</strong> Return on Equity indicates the profitability of a company in relation to shareholders' equity.
    </p>
    <p>
        <strong>Formula:</strong> Return on Equity (ROE) = (Net Income / Average Shareholders' Equity) * 100
    </p>

    <h2>Return on Employed Capital:</h2>
    <p>
        <strong>Definition:</strong> Return on Employed Capital assesses the efficiency of capital employed in generating profits for the company.
    </p>
    <p>
        <strong>Formula:</strong> Return on Employed Capital = (Net Operating Profit / Average Employed Capital) * 100
    </p>
      </b-card-text>
    </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow, BCol, BCard, BCardText, BButton,
  },
  directives: {
    Ripple,
  },
}
</script>
