import { $themeColors } from '@themeConfig'

export default {
  radialBarChart: {
    redrawOnWindowResize: true,
    series: [0,0,0],
    chartOptions: {
      colors: [$themeColors.primary, $themeColors.info, $themeColors.success],
      plotOptions: {
        radialBar: {
          size: 900, // Hier die Größe der Balken erhöhen
          hollow: {
            size: '35%',
          },
          track: {
            margin: 15,
          },
          dataLabels: {
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat',
            },
            value: {
              fontSize: '1rem',
              fontFamily: 'Montserrat',
            },
            total: {
              show: true,
              fontSize: '1rem',
              label: '',
              formatter() {
                return ''
              },
            },
          },
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      stroke: {
        lineCap: 'round',
        width: 1,
      },
      labels: ['Revenue', 'Free-Cashflow-Margin', 'Earnings-Margin'],
    },
  },
}
